// pages/_app.js
import '../styles/globals.css';  // Import global styles

// Define your theme with the correct colors
const theme = {
  type: 'light',  // You can switch to 'dark' if needed
  theme: {
  },
};

function MyApp({ Component, pageProps }) {
  return (
      <Component {...pageProps} />
  );
}

export default MyApp;
